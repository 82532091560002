export const core = {
  appName: 'Hondubet',

  currency: 'HNL',
  bonus: 'Bonos',
  prizes: 'Premios',
  notification: 'Notificaciones',
  hmac: 'hmac',
  lost: 'Perdida',
  won: 'Ganada',
  rollback: 'Rollback',
  cashout: 'Cashout',
  open: 'Abierta',
  canceladaVoid: 'Cancelada-Void',
  canceladaRejected: 'Cancelada-Rejected',
  pending: 'Pending',
  cashoutParcial: 'Cashout Parcial',
  today: 'Hoy',
  yesterday: 'Ayer',
  threeDaysAgo: 'Ultimos 3 días',
  oneWeekAgo: 'Ultimos 7 días', // ,
  last30Days: 'Ultimos 30 días',
  // aboutUs: 'Acerca de nosotros',
  accountConfiguration: 'Configuración de cuenta',
  // allGames: 'Todos los juegos',
  // allRightsReserved: 'Todos los derechos reservados',
  amountOfMoney: 'Cantidad de dinero',
  availableBalance: 'SALDO RETIRO',
  balance: 'Balance',
  bankDeposit: 'Depósito Bancario',
  // basketball: 'Baloncesto',
  // bettingLimit: 'Limite de apuesta',
  // bettingRules: 'Reglas de apuesta',
  birthDate: 'Fecha de nacimiento',
  bonuses: 'Bonificaciones',
  // bonusConditions: 'Condiciones de bono',
  // casino: 'Casino',
  casinoBonus: 'Bonos casino',
  casinoGames: 'Juegos de casino',
  cashPayment: 'Pago en Efectivo',
  changeYourEmail: 'Cambia tu correo electronico',
  chooseDepositMethod: 'Escoge tu método de depósito favorito:',
  cityRegion: 'Ciudad/Región',
  completed: 'Completado',
  completeProfile: 'Perfil completo',
  contactInformation: 'Información de contacto',
  contactPreferences: 'Preferencias de contacto',
  // contactUs: 'Contáctanos',
  continueWithFacebook: 'Entrar con Facebook',
  continueWithGoogle: 'Continuar con Google',
  creditCard: 'Tarjeta de Crédito/Débito',
  crypto: 'Crypto',
  currentBalance: 'Saldo actual',
  date: 'Fecha',
  // deposits: 'Depositos',
  depositBalance: 'SALDO RECARGA',
  depositInCrypto: 'Deposita en crypto',
  depositWithdrawals: 'Deposito/Retiros',
  // disputeResolution: 'Resolución de disputas',
  // doYouNeedHelp: '¿Necesitas ayuda?',
  email: 'Email',
  emailAddress: 'Correo electrónico',
  emailVerified: 'Email verificado',
  // esports: 'Esports',
  // faqs: 'Preguntas frecuentes',
  // favorites: 'Favoritos',
  firstName: 'Primer nombre',
  // followUs: 'Síguenos',
  forgotPassword: 'Se te olvidó la contraseña?',
  // forgotYourPassword: '¿Se te olvidó la contraseña?',
  gateway: 'Pasarela',
  // hockey: 'Hockey',
  // home: 'Inicio',
  identityDetails: 'Detalles de identidad',
  identityVerified: 'Identidad verificada',
  jackpots: 'Jackpots',
  // kycPolicies: 'Políticas de KYC',
  live: 'En Vivo',
  // liveEvents: 'Eventos en vivo',
  login: 'Login',
  loginHere: 'Entra aquí',
  loginTitle: 'Entrar a Hondubet',
  lottery: 'Loteria',
  logout: 'Cerrar sesión',
  loyaltyBonus: 'Bonus por lealtad',
  middleName: 'Segundo nombre',
  // moneyLaundering: 'Lavado de dinero',
  myPromotions: 'Mis promociones',
  new: 'Nuevos',
  nickname: 'Nickname',
  noAccount: 'No tienes cuenta?',
  noteOptional: 'Nota (Opcional)',
  notifications: 'Notificaciones',
  // ourSections: 'Nuestras secciones',
  // overview: 'Información General',
  password: 'Contraseña',
  personalAddress: 'Tu dirección personal',
  phoneNumber: 'Número de telefono',
  popular: 'Populares',
  // popularEvents: 'Eventos populares',
  // popularLeagues: 'Ligas populares',
  // profile: 'Perfil',
  // promotions: 'Promociones',
  // recommended: 'Recomendados',
  regionAnnouncements: 'Anuncios de región',
  register: 'Regístrate',
  registerTitle: 'Regístrate a Hondubet',
  // registerWith: 'Regístrate con',
  registerWithFacebook: 'Entrar con Facebook',
  registerWithGoogle: 'Continuar con Google',
  registerWithPhone: 'Regístrate con tu telefono',
  requested: 'Solicitado',
  // responsibleGaming: 'Juego responsable',
  saveChanges: 'Guardar cambios',
  search: 'Buscar',
  // securityAndPrivacy: 'Seguridad & privacidad',
  shareProfile: 'Compartir perfil',
  // signUp: 'Registrarse',
  slots: 'Slots',
  // soccer: 'Futbol',
  // sports: 'Deportes',
  sportsBonus: 'FREEBET (BONOS DEPORTIVOS)',
  sportsBets: 'Apuestas deportivas',
  // suggestedForYou: 'Sugeridos para ti',
  // support: 'Soporte',
  tableGames: 'Juegos de Mesa',
  // tennis: 'Tenis',
  // termsAndConditions: 'Términos & condiciones',
  totalBalance: 'Saldo Total: L',
  // tournaments: 'Torneos',
  transactionHistory: 'Historial de transacciones',
  transactions: 'Transacciones',
  type: 'Tipo',
  update: 'Actualizar',
  verify: 'Verificar',
  verifyYourAccount: 'Verifica tu cuenta',
  // volleyball: 'Voley',
  withdraw: 'Retirar',
  withdrawalMethod: 'Metodo de retiro',
  // withdrawals: 'Retiros',
  winningBets: 'Apuestas ganadas',
  // workWithUs: 'Trabaja con Nosotros',
  transactionApproved: 'Aprobado',
  transactionIssued: 'Solicitado',
  transactionPending: 'Pendiente',
  transactionRejected: 'Rechazado',
  transactionRollback: 'Rollback',
  transactionCancelled: 'Cancelado',
  transactionCompleted: 'Completado',
};
